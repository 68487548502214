import React from 'react'
import { FormattedMessage } from 'react-intl'

import { locales } from '../languages'
import Layout from 'src/components/Layout'
import Link from 'src/components/LocalizedLink'

const NotFoundPage = ({ path }) => {
  const locale = path.split('/')[1]

  if (!locales.includes(locale)) return null

  const slug = `/${path
    .split('/')
    .slice(2)
    .join('/')}`

  return (
    <Layout pageContext={{ locale, slug }}>
      <Link to={'/'}>
        <FormattedMessage id="pages.404.goBackHome" />
      </Link>
    </Layout>
  )
}

export default NotFoundPage
